function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    var _top = $e.offset().top + y;
    $('html,body').animate({"scrollTop" : _top}, duration);
    return false;
}

function placeholder () {
    var fields = $('.forma__field .forma__field__itext, .forma__field textarea');

    fields.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    fields.keyup(function () {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
        else $(this).parent().find('label').show();
    }).focusin(function () {
        $(this).parent().addClass('forma__inp_light');
    }).focusout(function () {
        $(this).parent().removeClass('forma__inp_light');
    });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    var body = $('body');
    var menu = $('#mm');
    var toggleBut = $('.menuToggle');
    var plusBut = $('#mm .mm__parent');

    toggleBut.click(function () {
        if (menu.css('display') == 'block') {
            menu.slideUp(300, 'linear');
            toggleBut.removeClass('active');
        }
        else {
            menu.slideDown(300, 'linear');
            toggleBut.addClass('active');
        }
    });

    plusBut.click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).next('.subs').slideUp(300, 'linear');
        } else if (!$(this).next().is('.subs:animated')) {
            $(this).addClass('active');
            $(this).next('.subs').slideDown(300, 'linear');
        }
    })

    $(window).resize(function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                menu.show();
                menu.find('.subs').each(function () { $(this).show(); })
            } else {
                if (!toggleBut.hasClass('active')) menu.hide();
                plusBut.each(function () {
                    if (!$(this).hasClass('active')) $(this).next('.subs').hide();
                });
            }
        }, 50);
    });
}

/********************************
******** END Menu mobile ********
********************************/

function searchBar () {
    $('.icon-search').click(function() {
        $(this).parent().addClass('active');
    });

    $('.icon-close-round').click(function() {
        $(this).parent().removeClass('active');
    });
}

function accordion () {
    var container = $('.accordionBlock');
    var link = $('.accordionBlock .accordionBlock__title a');

    link.click(function () {
        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active').next('.accordionBlock__box').slideUp(300, 'linear');
        } else if (!$(this).parent().next().is('div:animated')) {
            container.find('.active').removeClass('active').next('.accordionBlock__box').slideUp(300, 'linear');
            $(this).parent().addClass('active').next('.accordionBlock__box').slideDown(300, 'linear');
        }
        event.preventDefault();
    });

    link.eq(0).click();
}

function tableMobile () {
    $('.tableContent').each(function() {
        $(this).wrapAll('<div class="scrollXContainer" />');
    });
}

function tableSortExec () {
    var $table1 = $('.tablesorter')
        .tablesorter({
            //theme : 'blue',
            // this is the default setting
            cssChildRow : 'tablesorter-childRow',
            // initialize zebra and filter widgets
            widgets : [ 'zebra', 'filter', 'pager' ],
            widgetOptions : {
                // output default: '{page}/{totalPages}'
                // possible variables: {page}, {totalPages}, {filteredPages}, {startRow}, {endRow}, {filteredRows} and {totalRows}
                pager_output: '{startRow} - {endRow} / {filteredRows} ({totalRows})',
                pager_removeRows: false,
                // include child row content while filtering, if true
                filter_childRows : true,
                // filter child row content by column; filter_childRows must also be true
                filter_childByColumn : true,
                // class name applied to filter row and each input
                filter_cssFilter : 'tablesorter-filter',
                // Set this option to false to make the searches case sensitive
                filter_ignoreCase : true,
                filter_reset: '.reset'
            }
        });

    // hide child rows - get in the habit of not using .hide()
    // See http://jsfiddle.net/Mottie/u507846y/ & https://github.com/jquery/jquery/issues/1767
    // and https://github.com/jquery/jquery/issues/2308
    // This won't be a problem in jQuery v3.0+
    $table1.find('.tablesorter-childRow td').addClass('hidden');

    // Toggle child row content (td), not hiding the row since we are using rowspan
    // Using delegate because the pager plugin rebuilds the table after each page change
    // "delegate" works in jQuery 1.4.2+; use "live" back to v1.3; for older jQuery - SOL
    $table1.delegate('.toggle', 'click',function() {
        // use "nextUntil" to toggle multiple child rows
        // toggle table cells instead of the row
        $(this)
        .closest('tr')
        .nextUntil('tr.tablesorter-hasChildRow')
        .find('td')
        .toggleClass('hidden');
        return false;
    });

    // Toggle filter_childRows option
    $( 'button.toggle-combined' ).click( function() {
        var wo = $table1[0].config.widgetOptions,
        o = !wo.filter_childRows;
        wo.filter_childRows = o;
        $( '.state1' ).html( o.toString() );
        // update filter; include false parameter to force a new search
        $table1.trigger( 'search', false );
        return false;
    });
}

function popupGal() {
    if ($('.popup-gallery-0').length) {
        var options = {
            delegate: 'a',
            type: 'image',
            tLoading: 'Загружено #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
                titleSrc: function(item) {
                    return item.el.attr('title') + '<small></small>';
                }
            }
        };
        $('.popup-gallery-0').magnificPopup(options);
    }
}

function sendMsg () {
    var popup = $('.fixPopupMsg');
    var msg = $('.forma__sendMsg');
    var closeBut = $('.fixPopupMsg__close');
    
    var timeout;
    if (msg.find('div').length) {
        var classMsg = msg.find('div').attr('class');
        var textMsg = msg.find('div').text();
        popup.prepend('<div class="'+classMsg+'">'+textMsg+'</div>');
        popup.addClass('vis');
        timeout = setTimeout(function() {
            popup.animate({opacity : 'hide'}, 300, function() {
                $(this).removeClass('vis');
            });
        }, 5000);
        //popup.prepend('div class="'+classMsg+'"></div>')
    }

    closeBut.click(function() {
        clearTimeout(timeout);
        popup.animate({opacity : 'hide'}, 300, function() {
            $(this).removeClass('vis');
        })
    });
}

/********************************
********** Form Valid ***********
********************************/

var CheckForms = function (form, button, options) {
    this.form = form;
    this.button = form.find(button);

    this.options = $.extend({}, CheckForms.Defaults, options);

    if (this.options.radio) this.radioChange();

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    errorClass: 'errorField',
    emptyClass: 'emptyField',
    //agreement: 1,
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text() || ($(this).is('[type = checkbox') && !$(this).is('input:checked'))) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    this.form.find('.requiredField').each(function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        /**
         * @TODO:
         * 1. Get field type
         * 2. Check field by correct method (checkInput, checkRadio, checkCheckbox, checkEmail, checkDate)
         * 
         * checkDate by data-validate="date" + data-validatePattern="yyyy.mm.dd". Example: yyyy.mm.dd || dd.mm.yyyy
         **/ 
        if (!itemValue || !mailChecked || !dateChecked || itemValue == item.parent().find('label').text() || (item.is('[type = checkbox') && !item.is('input:checked'))) {
            item.addClass('errorField');
        }
        //if (item.is('[type = checkbox') && item.is('input:checked')) item.addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    //return patternEmail.test(value);

    this.form.on('keyup blur change', '.requiredField', function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        if (itemValue && itemValue != item.parent().find('label').text() && mailChecked && dateChecked) {
            item.removeClass('errorField emptyField');
        } else {
            itemValue == item.parent().find('label').text() ? item.addClass('emptyField') : item.addClass('errorField');
            if (!dateChecked) item.addClass('errorField');
        }

        if (item.is('[type = checkbox')) {
            item.is('input:checked') ? item.removeClass('errorField emptyField') : item.addClass('errorField');
        }

        var form = item.parents('form');
        var submitButton = form.find('.button_submit');
        if (!form.find('.emptyField').length && !form.find('.errorField').length) {
            submitButton.removeClass('button_disabled');
        } else {
            submitButton.addClass('button_disabled');
        }
    });
}

CheckForms.prototype.checkEmail = function (value) {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    return patternEmail.test(value);
}

CheckForms.prototype.checkAllElements = function () {
    if (/*this.form.data('agreement') && */!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
        this.button.removeClass('button_disabled');
    } else {
        this.button.addClass('button_disabled');
    }
}

CheckForms.prototype.submitForm = function () {
    this.button.click(function () {
        this.checkCorrect();
        event.preventDefault();
        if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
            this.form.submit();
        }
    }.bind(this));
}

function execSubscribeForm() {
    var subscribeForm = new CheckForms($('#subscribeForm'), '.button_submit');
}

/********************************
******** END Form Valid *********
********************************/

function initMap() {
    var mapCenter = $('meta[name="map-center"]').attr('content');

    ymaps.ready(function () {
        var myMap = new ymaps.Map('map', {
            center: mapCenter.split(','),
            zoom: 16
        }, {
            searchControlProvider: 'yandex#search'
        });

        myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

    	for (var key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
    		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>',
    		        balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>',
    		        balloonContentFooter: '<div class="mbm">' + places[key][4] + '</div>'

    		    },
    		    {
                    preset: 'islands#dotIcon',
                    iconColor: '#911800'
    		    }
    		);
    		myMap.geoObjects.add(placesExec);
    	}
    });
}


x.exe['option-default'] = ['menuToggle()', 'placeholder()', 'searchBar()', 'accordion()', 'popupGal()', 'tableMobile()'];
x.exe['option-registry'] = ['tableSortExec()'];
x.exe['option-index'] = ['execSubscribeForm()', 'sendMsg()'];
x.exe['option-news'] = ['execSubscribeForm()', 'sendMsg()'];
x.exe['option-contacts'] = ['initMap()', 'execContactsForm()', 'sendMsg()'];
